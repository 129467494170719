.InputWPNumber {
  margin: 5px 0;
}

.InputWPNumber input {
  font-family: 'Inter';
  text-align: left;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.InputWPText {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.05em;
  color: #FFFFFF;
}

.InputWPArrow {
  margin-right: 5px;
}

.InputWPArrow.arrowdown {
  transform: rotateX(180deg);
}

.inputWPTextLoading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #00FF64;
  display: flex;
  align-items: center;
}

.inputWPloader {
  margin-right: 5px;
  animation: rotate 3.2s infinite;
  background-size: cover;
}

.inputDuble {
  position: relative;
  min-width: 43px;
  margin-right: 5px;
}
.inputIcon.mini {
  position: absolute;
}
.inputIcon.mini img {
  width: 25px;
}
.miniTop {
  top: -29px;
}
.miniLine {
  position: absolute;
  width: 24px;
  height: 1px;
  top: -3px;
  background-color: aliceblue;
}
.miniBottom {
  top: 2px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}