.wrapperInput {
  display: flex;
  position: relative;
  width: calc(100%-48px);
  justify-content: space-between;
  align-items: center;
  background: #04010a;
  border: 1px solid #fff;
  padding: 15px 24px;
}
input {
  width: 100%;
  background-color: black;
  color: #fff;
  text-align: right;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  font-family: "Inder", sans-serif;
  outline: none;
}
.wrapperInput:hover {
  border: 1px solid #00ff64;
}
.wrapperInput.activ {
  border: 1px solid #00ff64;
}
.wrapperInput.error {
  border: 1px solid #ff0000;
}
.wrapperInput.error:hover {
  border: 1px solid #00ff64;
}
.wrapperInput.error input {
  color: #ff0000;
}

.wrapperInput.isWeth:hover {
  border: 1px solid #ff0090;
}

.wrapperInput.isWeth.activ {
  border: 1px solid #ff0090;
}
.wrapperInput.isWeth.error {
  border: 1px solid #ff0000;
}
.wrapperInput.isWeth.error:hover {
  border: 1px solid #ff0090;
}
.wrapperInput.isWeth.error input {
  color: #ff0000;
}

.inputInfo {
  width: 65%;
  display: flex;
  align-items: center;
}

.inputIcon {
  margin-right: 16px;
}

.inputTexts {
  text-transform: uppercase;
}
.topText {
  text-transform: uppercase;
  font-size: 16px;
}
.downText {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  word-wrap: break-word;
}

.inputNumber {
  display: flex;
  justify-content: end;
  width: 35%;
}

