.withdrawButton {
    width: 216px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    border-radius: 2px;
    margin-left: 20px;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.withdrawButton img{
    margin-left: 10px;
}

.WBContainer {
    padding: 0 auto;
}

.buttonDefolt {
    background-color: #00FF64;
}
.buttonOk{
    background: #2FA05C;
}
.buttonLoad{
    background: #2B6542;
}
.buttonRejected{
    background: #A03D2F;
}
.buttonConfirmed{
    background: #2FA05C;
}
.buttonVerification{
    background: #2B6542;
}
.buttonDefolt:hover  {
    background-color: #00ff66d5;
}
.buttonOk:hover {
    background: #2FA05Cd5;
}
.buttonLoad:hover {
    background: #2B6542d5;
}
.buttonRejected:hover {
    background: #A03D2Fd5;
}
.buttonConfirmed:hover {
    background: #2FA05Cd5;
}
.buttonVerification:hover {
    background: #2B6542d5;
}
@media (max-width: 800px) {
    .withdrawButton {
        margin-left: 0px;
        height: 45px;
    }
    
  }