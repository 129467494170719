
.WDVariantContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 0;
}
.WDVariantContainer.activ{
  color:#00FF64;
} 
.WDVariantNumber{
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
}
.WDVariantCod{
  margin-right: 10px;
  flex: 1 1 auto;
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
}
.WDVariantQuantity{
  margin-right: 5px;
}
.WDVariantName{
  margin-right: 5px;
}
.WDVariantСondition{
  display: flex;
}
.WDVariantName{
  display: flex;
  align-items: center;
}
