.wrapper {
  width: 100%;
  overflow: hidden;
  color: #fff;

  font-weight: 400;
  background-size: cover;
  z-index: -10;

  font-family: "Inter", sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0px 61px;
}

.header {
  position: fixed;
  top: 0;
  margin-top: 40px;
}
.main {
  flex: 1 0 auto;
  overflow: auto;

  max-width: 500px;
}
@media screen and (min-width: 1300px) {
  .main {
    width: 500%;
  }
}
@media screen and (max-width: 1299px) {
  .main {
    width: 60%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding: 0px 16px;
  }
  .main {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .main {
    width: 100%;
  }
}
