.backGroundFooter {
  width: 100%;
  height: 200px;
  position: fixed;

  background-position: 80% center;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 40%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

@media screen and (max-width: 900px) {
  .footerLink {
    font-size: 13px;
  }
}
@media screen and (max-width: 800px) {
  .backGroundFooter {
    height: 30%;
  }
  .footerLink {
    font-size: 16px;
  }
}
.footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
  width: 100%;
}
.footer.burger {
  z-index: 11;
}
.footerButton{
  align-items: center;
  display: flex;
  border: 1px solid #fff;
  color:#fff;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
  transition: .6s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.footerButton:hover{
  color:#00ff64
}

.footerButton img{
  margin-left: 5px;
} 
.footerLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  
}
@media screen and (max-width: 1300px) {
  .footerLinks {
    flex-direction: column;
  }
}
.footerLink {
  margin-right: 10px;
}
.green {
  color: #00ff64;
}
.grey {
  color: rgba(255, 255, 255, 0.5);
}
.footerLogo {
  width: 30%;
  max-width: 380px;
  margin: 0 5% 0;
}
.footerLogo img {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
  }
  .footerLinks {
    flex-direction: column;
    align-items: center;
  }
  .footerLink {
    margin-right: 0;
  }
  .footerLogo {
    width: 100%;
    max-width: 380px;
    margin: 25px 5% 0;
  }
  .footerButton{

    display: none;

  }
}
