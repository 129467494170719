.disconWal_back {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.541);
  z-index: 1000;
}
.disconWal_wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 430px;
  background: #ffffff;
  color: #000;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: 1px solid #00ff64;
  box-shadow: 0px 0px 30px #00ff64;
  border-radius: 2px;
  padding: 24px 44px 40px;
}

.disconWalTitle {
  font-weight: 500;
  font-size: 16px;
  color: black;

  padding-bottom: 22px;
}
.disconWalTitle span:first-child {
  display: block;
}
.disconWalTitle span:nth-child(2) {
  display: none;
}
.disconWalName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #00ff64;
  padding: 8px 12px;
  margin-bottom: 20px;
}
.disconWalNameLeft {
  display: flex;
  align-items: center;
}
.disconWalTr {
  margin: 0 8px;
  border-radius: 50%;
}
.disconWalButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.disconWalButton {
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #000;
  padding: 10px 0px;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.disconWalButton:hover {
  border: 1px solid rgba(0, 0, 0, 0.7);
}
.disconWalButton.green {
  background: #00ff64;
  border: 1px solid #00ff64;
  color: #000;
}
.disconWalButton.green:hover {
  background: #00ff47;
  border: 1px solid #00ff47;
  color: #000;
}

@media (max-width: 800px) {
  .disconWal_wrapper {
    width: 100%;
    padding: 26px 24px;
  }
  .disconWal_wrapper {
    width: calc(100% - 132px);
  }
  .disconWalTitle span:first-child {
    display: none;
  }
  .disconWalTitle span:nth-child(2) {
    display: block;
  }
}
