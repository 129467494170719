.withdrawVariants{
 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - 580px);
    margin-top: 5px;
  }
  .variants{
    overflow: auto;
  }
.variantLast{
  margin-bottom: 15px;
}
  @media screen and (max-width: 800px) {
    .withdrawVariants{
        max-height: calc(100vh - 550px);
      }
  }
    