.burger {
  position: absolute;
  display: none;
}

.menu {
  position: absolute;
  display: none;
}

@media (max-width: 800px) {
  .burger {
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: 23px;
    cursor: pointer;

    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .burger_circle {
    display: block;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff;
    margin-bottom: 2px;
    margin-top: 2px;
    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .burger:hover {
    background: #a5a5a510;
  }

  .burger:hover .burger_circle {
    background-color: rgb(146, 146, 146);
  }

  .menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 auto;
    width: 100%;

    height: calc(100% - 92px);
    background-color: rgb(0, 0, 0);
    z-index: 10;
    padding-top: 92px;
    left: 0;
    top: 0;
  }
  .menuBody {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
  }
}
