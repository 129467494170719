.listQrContainer {
  margin: 45px;

}

.containerWallets {
  
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  color: 000;
}

form {
  width: 100%;
  display: flex;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 2px;

  input {

    background-color: #fff;
    text-align: left;
    color: #000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-left: 15px;
  }

  button {
    background-color: transparent;
    border: 0px;
    padding: 10px 15px;
  }
}
.paginatorContainer{
  display: flex;
  max-width: 120px;
  justify-content: space-around;
  margin: 0 auto;

  button{
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent;
    margin: 0 5px;
    border-radius: 2px;
    padding: 4px 9px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
  }
  
}
.paginatorContainer.white{
  
  button{
    color: rgba(255, 255, 255, 0.5);
  }
}
.paginatorContainer.white{
  
  button:hover{
    border: 1px solid rgba(255, 255, 255);
    color: rgba(255, 255, 255);
  }
}
@media screen and (max-width: 800px) {
  .paginatorContainer.white{
    display: none;
    button{
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
  