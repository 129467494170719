.backGroundImg {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("../../img/home/Back.jpg");
  background-size: cover;
  background-repeat: repeat-y;

  background-position: 80% center;

  top: 0;
  left: 0;
  z-index: -2;
}

.titleLine {
  display: flex;
  width: 40px;
  height: 1px;
  margin: 0 15px;
  background: rgb(255, 255, 255);
}

.titleCost {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.titleCost img {
  margin-right: 5px;
}
.costImg {
  height: 54px;
}

.prosent {
  position: relative;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: rgb(255, 255, 255);
}
.prosentImg {
  height: 15px;
}

.backGroundLeft {
  width: 60%;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.6) 40%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: -1;
  opacity: 1;
}
.block1Text {
  font-weight: 300;
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.05em;
  color: #ffffff;
}
@media screen and (max-width: 500px) {
  .backGroundLeft {
    opacity: 0;
  }
}

.block1 {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 18px;
}

.block1 span {
  display: block;
  width: 17px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.4);
}

.block2 {
  margin-top: 30px;
}

.block3 {
  margin-top: 66px;
}

.block4 {
  width: 98%;
  margin-top: 24px;
  position: relative;
}

.errorText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  margin-top: 10px;
}

.block5 {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.block6 {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.block6 span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-left: 15px;
  color: #ff0090;
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 500px) {
  .titleLine {
    width: 17px;
  }
  .block1Text {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
  .costImg {
    height: 24px;
  }
  .prosent {
    font-size: 12px;
    line-height: 15px;
    top: 5px;
  }
  .prosentImg {
    height: 15px;
  }
  .block5 {
    justify-content: center;
  }
}
