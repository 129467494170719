.policeBackGroundImg {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("../../img/police/policy_Back.jpg");
  background-size: cover;
  background-repeat: repeat-y;

  background-position: 80% center;

  top: 0;
  left: 0;
  z-index: -2;
}
.policeBlock {
  margin-top: 40px;
}
.policeBlock span {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
}
.policeBlock p {
  font-weight: 400;
  font-size: 14px;
}
