.qrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.qrContainer img {
  margin: 10px;
  width: 100%;
  max-width: 240px;
}
.qrText {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin: 24px;
}
