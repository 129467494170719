.converButton {
  position: absolute;
  width: 32px;
  height: 32px;
  color: #fff;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  border: 1px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    color: #00ff64;
    border: 1px solid #00ff64;
  }
  .activ {
    color: #fff;
    background-color: #00ff64;
    border: 1px solid #00ff64;
  }
  .disabled {
    color: rgba(255, 255, 255, 0.1);
    background-color: #000000;
    border: 1px solid #000000;
  }
  img {
    position: relative;
    top: 1px;
    color: #00ff64;
  }
}
