.WDReadyIMG {
  margin-top: 60px;
}

.WDReadyTextL {
  margin-top: 28px;
  font-size: 32px;
  line-height: 39px;
}

.WDReadyTextS {
  margin-top: 12px;
  font-size: 16px;
  line-height: 19px;
}

.WDReadyButton {
  margin-top: 32px;
  margin-bottom: 51px;
  cursor: pointer;
}