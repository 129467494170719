.listQR{
  width: 100%;
}
.conWal_back {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.541);

  z-index: 1000;
}
.conWal_wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 430px;

  background: #ffffff;
  color: #000;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: 1px solid #00ff64;
  box-shadow: 0px 0px 30px #00ff64;
  border-radius: 2px;
}

.conWalTitle {
  font-weight: 500;
  font-size: 16px;
  color: black;
  margin-top: 26px;
}
.conWalContainerBotton {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 2px;
}
.conWalButton {
  color: #000;
  padding: 7px 30px;
  cursor: pointer;
}
.conWalButton.greenButton {
  background: #00ff64;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.conWalButton.greenButton:hover {
  background: #00ff47;
}

.conWalButton.lineEffect {
  position: relative;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.conWalButton.lineEffect:hover {
  color: #000000;
}
.conWalButton.lineEffect:after {
  content: "";
  display: block;
  position: absolute;
  left: 20%;

  width: 0%;
  height: 1px; /*задаём ширину линии*/
  background-color: #000000; /*задаём цвет линии*/
  transition: width 0.3s ease-out; /*задаём время анимации*/
}
.conWalButton.lineEffect:hover:after {
  width: 60%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
}

@media (max-width: 800px) {
  .conWal_wrapper {
    width: calc(100% - 32px);
    margin-right: 16px;
  }
}
