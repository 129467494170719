.containerQrItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qrItemImg {
  margin: 14px;
  width: 56px;
  height: 56px;
  background-color: #3205B3;;
}
.qrItemName {
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;
  text-align: center;
}
