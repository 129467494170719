.backGroundHeader {
  width: 100%;
  height: 200px;
  position: absolute;
  background-position: 80% center;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 40%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

.header_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
}

.header_logo img {
  width: 100%;
  max-width: 220px;
}

.menu_items {
  min-width: 460px;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  cursor: pointer;
  padding-bottom: 6px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.rigth_container {
  height: 100%;
  display: flex;
  align-items: center;
}

.balanceContainer {
  display: none;

  align-items: center;
  appearance: none;
  border: 0;
  position: relative;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    border: 1px solid #00ff64;
  }

  img:first-child {
    margin-right: 0px;
  }
}

.loadingMobileD {
  display: block;
  width: 28px;
}

@media (max-width: 800px) {
  .backGroundHeader {
    position: fixed;
    width: 100%;
    height: 100vh;
    position: absolute;
    background-position: 80% center;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 22%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
  .rigth_container {
    right: 50px;
    display: flex;
    top: -6px;

    .balanceContainer {
      padding: 8px 12px;
      display: flex;
      background-color: transparent;
    }
  }
  .rigth_container {
    margin-right: 60px;
  }
  .menu_items {
    display: none;
  }

  .header_logo img {
    width: 80%;
    max-width: 122px;
  }

  .header_container {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .header_logo {
    width: 100%;
    justify-content: left;
  }
}

.loadingMobile {
  width: 24px;
  height: 24px;
  top: 5px;
  right: 8px;
  position: absolute;
  animation: spinMobile 3.2s linear;
  animation-fill-mode: forwards;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.loadingMobileStatic {
  width: 24px;
  height: 24px;
  top: 5px;
  right: 8px;
  position: absolute;
  background-image: url("../../img/menu/icon_mobile_connect.svg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-size: contain;
  background-position: 80% center;
  transform: rotate(360deg) scale(1 1);
}

@keyframes spinMobile {
  0% {
    background-image: url("../../img/menu/icon_loading.svg");
    transform: rotate(0deg) scale(1);
  }

  61% {
    transform: rotate(360deg) scale(1);
  }

  71% {
    background-image: url("../../img/menu/icon_loading.svg");
    transform: rotate(360deg) scale(0);
  }

  81% {
    background-image: url("../../img/menu/icon_mobile_ok.svg");
    transform: rotate(360deg) scale(1);
  }

  91% {
    background-image: url("../../img/menu/icon_mobile_connect.svg");
    border-radius: 0%;
    transform: rotate(360deg) scale(1 0);
    background-position: 80% center;
    background-size: contain;
  }

  100% {
    background-image: url("../../img/menu/icon_mobile_connect.svg");
    border-radius: 0%;
    background-size: contain;
    background-position: 80% center;
    transform: rotate(360deg) scale(1 1);
  }
}
