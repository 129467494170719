.withdrawBackGroundImg {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("../../img/withdraw/withdrawBack.jpg");
  background-size: cover;
  background-repeat: repeat-y;
  background-position: 80% center;
  top: 0;
  left: 0;
  z-index: -2;
}


.withdrawContainer {
  width: 60%;

  position: absolute;
}



.withdrawBlockSearch {
  width: 100%;

  display: flex;
  flex-direction: row;
  max-width: 1300px;
}

.withdrawBlockSearchInputs {
  width: calc(100% - 236px);

}
.containerOversize{
  width: calc(100% + 236px);
}

.withdrawBlockSearchButtons {
  width: 216px;
  height: 80px;
  margin: 0;

}

.withdrawBlockSearchText {

  margin-top: 32px;
  border: 1px solid #fff;
  padding: 12px 0;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

@media (max-width: 800px) {
  .withdrawContainer {
    width: calc(100% - 40px);

    position: absolute;
  }

  .withdrawBlockSearch {
    width: 100%;
    display: flex;

    flex-direction: column-reverse;
  }

  .withdrawBlockSearchInputs {
    width: 100%;
    height: calc(100vh - 400px);

  }

  .withdrawBlockSearchButtons {
    height: 40px;
    margin: 15px 0;
  }

  .withdrawBlockSearchText {
    margin-top: 12px;
    width: calc(100%);

  }

  .withdrawBlockSearchText {
    font-size: 12px;
  }
  .containerOversize{
    width: 100%;
  }
}