.flex {
  display: flex;
}
.buttonConnectWallet {
  min-width: 370px;
  justify-content: end;
}
.button {
  display: flex;
  align-items: center;
  appearance: none;
  border: 0;
  position: relative;
  font-weight: 500;
  font-family: "Inder", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  padding: 18px 36px;
  cursor: pointer;
  border-radius: 2px;
  transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
button.transparent {
  color: #00ff64;
  background: transparent;
}
.transparent .lineEffect {
  display: inline;
}
.transparent .lineEffect:hover {
  color: #00ff47;
}
.transparent .lineEffect:after {
  content: "";
  display: block;
  position: absolute;
  left: 20%;

  width: 0%;
  height: 1px; /*задаём ширину линии*/
  background-color: #00a650; /*задаём цвет линии*/
  transition: width 0.3s ease-out; /*задаём время анимации*/
}
.transparent .lineEffect:hover:after {
  width: 60%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
}

.button.green {
  background: #00ff64;
  color: rgb(0, 0, 0);
}
.button.green:hover {
  background: #00ff47;
}

.button.halvetransparent {
  background: rgba(0, 255, 100, 0.2);
  color: rgb(255, 255, 255);
  border: 1px solid #00ff64;
}
.button.halvetransparent:hover {
  background: #00ff4880;
}

.button.out {
  padding: 18px 28px;
  border-left: 0px;
}
.balance {
  margin-right: 30px;
}
.containerinfoIcon {
  width: 32px;
  height: 100%;
  position: relative;
}
.infoIcon.connectStatik{
  width: 32px;
  height: 32px;
  top: -6px;
  left: -6px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-image: url("../../img/menu/tr.svg");
}
.infoIcon.loading {
  width: 32px;
  height: 32px;
  top: -6px;
  left: -6px;
  position: absolute;
  
  animation: spin 3.2s linear;
  animation-fill-mode: forwards;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    background-image: url("../../img/menu/icon_loading.svg");
    transform: rotate(0deg) scale(1);
  }
  61% {
    transform: rotate(360deg) scale(1);
  }
  71% {
    background-image: url("../../img/menu/icon_loading.svg");
    transform: rotate(360deg) scale(0);
  }
  81% {
    background-image: url("../../img/menu/icon_mobile_ok.svg");
    transform: rotate(360deg) scale(1);
  }
  91% {
    background-image: url("../../img/menu/tr.svg");
    transform: rotate(360deg) scale(1 0);
  }
  100% {
    background-image: url("../../img/menu/tr.svg");
    transform: rotate(360deg) scale(1 1);
  }
}

.infoIcon {
  margin-right: 8px;
}
@media screen and (max-width: 400px) {
  .button {
    appearance: none;
    border: 0;
    position: relative;
    font-weight: 500;
    font-family: "Inder", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}
