.accountBackGroundImg {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("../../img/account/account_back.jpg");
  background-size: cover;
  background-repeat: repeat-y;

  background-position: 80% center;

  top: 0;
  left: 0;
  z-index: -2;
}
.blockTitle {
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
}
.blockTitle h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  letter-spacing: 0.05em;
  margin: 0px;
  margin-bottom: 10px;
}
.line {
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.4);
  margin-bottom: 60px;
}

.accountBlock {
  width: 98%;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 40px;
}
.accountBlock span {
  display: block;
  margin-bottom: 20px;
}
@media (max-width: 800px) {
  .line {
    margin-bottom: 15px;
  }
  .blockTitle h2 {
    font-size: 24px;
  }
}