$green: #60f000;

body {
  background-color: #000000;
}
.App {
  height: 100%;
}

h1 {
  color: #00ff64;
  font-size: 42px;
  font-weight: 700;
}

h2 {
  font-weight: 400;
  font-size: 24px;
}
p {
  font-size: 18px;
}
p ~ p {
  margin-top: 30px;
}
a {
  text-decoration: none !important;
}
::-webkit-scrollbar {
  height: 4px;
  width: 0px;
  border: 1px solid #000000;
  background: #000000;
}

@media screen and (max-width: 500px) {
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}
